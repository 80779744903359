<script setup lang="ts">
import {object, string, type InferType, mixed, bool} from 'yup'
import type {FormSubmitEvent} from '#ui/types'

const url = useRequestURL()

const schema = object({
  email: string().email('Invalid email').required('Champ obligatoire'),
  nom: string().required('Champ obligatoire'),
  prenom: string().required('Champ obligatoire'),
  mobile: string().required('Champ obligatoire'),
  dateBirth: string().required('Champ obligatoire'),
  zipcode: string().required('Champ obligatoire'),
  city: string().required('Champ obligatoire'),
  address: string().required('Champ obligatoire'),
  gender: string().required('Champ obligatoire'),
  file: mixed().required('Champ obligatoire'),
  isAcceptCgu: bool().required('Champ obligatoire')
})

type Schema = InferType<typeof schema>

const state = reactive({
  email: undefined,
  nom: undefined,
  prenom: undefined,
  mobile: undefined,
  dateBirth: undefined,
  zipcode: undefined,
  city: undefined,
  address: undefined,
  gender: undefined,
  file: undefined,
  isAcceptCgu: undefined
})

const radioGenre = [{
  value: 'Monsieur',
  label: 'Monsieur'
}, {
  value: 'Madame',
  label: 'Madame'
}]
const toast = useToast()

const onSubmit = async (event: FormSubmitEvent<Schema>) => {
  if(!grecaptcha.getResponse()) {
    toast.add({
      title: 'Erreur !',
      description: 'Vérifier le reCaptcha SVP ! Merci !',
      icon: 'i-heroicons-minus-circle',
      color: 'red'
    })
  } else {
    const token = grecaptcha.getResponse();
    const reponseCaptcha = await useAsyncData(() => $fetch(`/api/verifyCaptcha?secret=6Ld8_KIlAAAAAC93xAz9THC4enRAcZHs4bI_R6_O&response=${token}`));
    if(reponseCaptcha.data.value.success === true) {
      const formData = new FormData()
      formData.append('file', event.data.file as Blob)

      const uploadFile = await $fetch('/api/uploadfile', {
        method: 'POST',
        body: formData
      })

      const msgBody = ref("");

      msgBody.value = `
        [POLAIRE] Vous avez un message depuis votre site web de la part de :<br /><br />
        ${event.data.gender} ${event.data.nom} ${event.data.prenom} <br/>
        Date de naissance: ${event.data.dateBirth} <br/>
        Email : ${event.data.email} <br/>
        Téléphone: ${event.data.mobile} <br/>
        Adresse: ${event.data.address} <br/>
        Ville: ${event.data.city} <br/>
        Code postal: ${event.data.zipcode} <br/>

        Sujet: Participation au jeu à l'occasion de la sortie du film Largo Winch Le Prix de l'argent <br/><br/>

        Son message: <br/><br/>
        Bonjour ! <br>
        Je souhaite participer au jeu, ci-joint mon ticket de caisse (ou copier-coller ce lien) : <a href="//${url.host}/tickets-files/${uploadFile}" target="_blank">Cliquer ici</a>
       `

        const { data } = await useAsyncData(() =>$fetch('https://ws2-gbbu02.graphibox.eu/EpContact.ws', {
          method: 'POST',
          body: {
            pKey: "HKfkw2QtyKaPjuts4QkGZ2fDa3Pkn57nd2", // this is an API key for testing purpose only, change this when it is in production,
            pType: "web-contact",
            pFct: "SetContact",
            pMsgLastname: `${event.data.nom}`,
            pMsgFirstname: `${event.data.prenom}`,
            pMsgPhone: `${event.data.mobile}`,
            pMsgEmail: `${event.data.email}`,
            pMsgSubject: "Participation au jeu à l'occasion de la sortie du film Largo Winch Le Prix de l'argent",
            pMsgMessage: `${msgBody.value}`
          }
        }))
        if (data !== undefined) {
          toast.add({
            title: 'Email envoyé',
            description: 'Votre participation a été enregistrer. Merci !',
            icon: 'i-heroicons-check-badge'
          })
        }
    } else {
      toast.add({
        title: 'Erreur !',
        description: 'Vérification du reCaptcha à échouer, réessayer ultérieurement. Merci ! ',
        icon: 'i-heroicons-minus-circle',
        color: 'red'
      })
    }
  }
}

const onError = async (event: any) => {
  toast.add({
    title: 'Erreur !',
    description: 'Vérifier les champs du formulaire SVP ! Merci !',
    icon: 'i-heroicons-minus-circle',
    color: 'red'
  })
}

const handleFileChange = (event: any) => {
  state.file = event[0]
}

let scriptTag;
onMounted(() => {
  setTimeout(() =>{
    var divRecaptcha = document.getElementById("divRecaptcha")
    document.getElementById("recaptcha")?.remove();
    divRecaptcha.innerHTML = `<div id="recaptcha" class="g-recaptcha" data-sitekey="6Ld8_KIlAAAAAC_-drK1PJB1fmSD6yQDbr3Uii8Q"></div>`;
    scriptTag = document.createElement("script");
    scriptTag.setAttribute("src", "https://www.google.com/recaptcha/api.js");
    scriptTag.setAttribute("defer", "");
    scriptTag.setAttribute("async", "");
    document.body.appendChild(scriptTag);
  }, 1000)
})

</script>

<template>
  <div class="form ml-330 mr-330 mb-20">
    <div class="title mb-5">Formulaire de participation</div>
    <UForm :schema="schema" :state="state" class="space-y-4 formParticipate" @submit="onSubmit" @error="onError">
      <div class="formGroup">
        <UFormGroup label="" name="gender" required>
          <URadioGroup v-model="state.gender" :options="radioGenre" class="radioGenreFieldSet" />
        </UFormGroup>
      </div>
      <div class="formGroup">
        <UFormGroup label="Nom" name="nom" required>
          <UInput v-model="state.nom" placeholder="Votre réponse..." color="green"/>
        </UFormGroup>

        <UFormGroup label="Prenom" name="prenom" required>
          <UInput v-model="state.prenom" placeholder="Votre réponse..." color="green"/>
        </UFormGroup>
      </div>
      <div class="formGroup">
        <UFormGroup label="Email" name="email" required>
          <UInput v-model="state.email" placeholder="Votre réponse..." color="green"/>
        </UFormGroup>

        <UFormGroup label="Mobile" name="mobile" required>
          <UInput v-model="state.mobile" color="green" placeholder="Votre réponse..."/>
        </UFormGroup>
      </div>
      <div class="formGroup">
        <UFormGroup label="Date de naissance" name="dateBirth" required>
          <UInput v-model="state.dateBirth" color="green" placeholder="Votre réponse..."/>
        </UFormGroup>

        <UFormGroup label="Code postal" name="zipcode" required>
          <UInput v-model="state.zipcode" color="green" placeholder="Votre réponse..."/>
        </UFormGroup>
      </div>
      <div class="formGroup">
        <UFormGroup label="Ville" name="city" required>
          <UInput v-model="state.city" color="green" placeholder="Votre réponse..."/>
        </UFormGroup>

        <UFormGroup label="Adresse" name="address" required>
          <UInput v-model="state.address" color="green" placeholder="Votre réponse..."/>
        </UFormGroup>
      </div>
      <div class="formGroup">
        <UFormGroup label="Importez votre ticket de caisse" help="Fichier inférieur à 5 Mo (formats .jpg, .png, .gif, .pdf acceptés)" required>
          <UInput type="file" @change="handleFileChange" icon="i-heroicons-folder" />
          <!--<input type="file" @change="handleFileChange" />-->
        </UFormGroup>
      </div>
      <UCheckbox name="cgu" v-model="state.isAcceptCgu" label="J’ai lu et j’accepte le règlement du jeu et le traitement des données dans le cadre du jeu concours." required />
      <div id="divRecaptcha">
        <div id="recaptcha" class="g-recaptcha" data-sitekey="6Ld8_KIlAAAAAC_-drK1PJB1fmSD6yQDbr3Uii8Q"></div>
      </div>
      <UButton type="submit" class="submitBtn">
        <UIcon name="i-heroicons-check" />
        Valider ma participation
      </UButton>
    </UForm>
  </div>
</template>

<style scoped>
.form {
  background-color: #000000;
  padding: 80px 120px;
}

.title {
  text-align: center;
  /* glow-white */
  text-shadow: 0 0 12px rgba(255, 255, 255, 0.40), 0 20px 25px rgba(0, 0, 0, 0.10), 0 10px 10px rgba(0, 0, 0, 0.04);
  font-family: "Zeroes Two", sans-serif;
  font-size: 44px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 52px */
  letter-spacing: 2.08px;
  text-transform: uppercase;
}

.ml-330 {
  margin-left: 330px;
}

.mr-330 {
  margin-right: 330px;
}

.form label {
  color: #fff;
}

.formParticipate {
  display: flex;
  flex-direction: column;
}

.formParticipate .formGroup {
  display: flex;
}

.radioGenreFieldSet:deep(fieldset) {
  display: flex;
  column-gap: 2rem;
}

.formGroup:deep(div) {
  width: 95%;
}

:deep(label) {
  color: #fff;
  font-family: Poppins-Regular, sans-serif;
}

:deep(p) {
  color: #A1A1AA;
  font-family: Poppins-Regular, sans-serif;
}

.formGroup:deep(input[type='text']) {
  width: 100%;
  background-color: #18181B;
  color: #fff;
}

.submitBtn {
  width: fit-content;
  margin: 2rem auto;
  color: #000;
  font-family: Poppins-Regular, sans-serif;
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .form {
    padding: 1rem;
  }

  .form :deep(label) {
    font-size: 13px;
  }

  .title {
    font-size: 28px;
  }

  .ml-330 {
    margin-left: 1rem;
  }

  .mr-330 {
    margin-right: 1rem;
  }
}
</style>